import { useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { GoInfo } from "react-icons/go"
import { NewsBar } from "./_utils/Elements"
import { CodeSnippet, IOSectionNetwork, IOSectionHelloWorld } from "./HomeDemo"
import { handleGtag } from "./_utils/gtag"
import { usecaseList, modelList } from "./commonItems"
import { ModelButton } from "./LLM"


export const Home = ({ tabletMode, mobileMode }) => {
  const [displayExp, setDisplayExp] = useState(null)
  const [demoId, setDemoId] = useState(null)
  const [selectedModelId, setSelectedModelId] = useState(null)
  const [modelStartId, setModelStartId] = useState(0)
  const defaultNum = Math.floor(window.innerWidth / 300)

  const isSmallScreen = mobileMode || tabletMode
  const containerStyle = { maxWidth: mobileMode ? "100%" : tabletMode ? "700px" : "1200px", display: "flex", flexDirection: "column", alignItems: "center" }

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <link rel="canonical" href="https://versi0n.io" />
        <title>version | Agentic orchestration framework for task automation</title>
      </Helmet>

      <div className="container content" style={{ padding: isSmallScreen ? "50px 20px" : "100px 50px", ...containerStyle, minHeight: mobileMode ? undefined : tabletMode ? "800px" : undefined }}>

        <NewsBar
          newsTitle={"16K+ Downloads"}
          link={"https://clickpy.clickhouse.com/dashboard/versionhq"}
          fontSize={isSmallScreen ? "12px" : "16px"}
        />

        <h1 style={{ lineHeight: 1.5, fontWeight: 700, padding: "0 0 10px 0" }} itemProp="description">
          Build and share <span style={{ background: "-webkit-linear-gradient(left, #00d1b2, #169d87)", backgroundClip: "text", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>agentic workflows</span>
        </h1>

        <div style={{ maxWidth: "1000px", textAlign: "center" }}>
          <h2 style={{ fontSize: "16px", fontWeight: 500 }}>
            A Python framework for agent networks that handle complex task automation without human interaction
          </h2>
          <h3 style={{ fontSize: "14px", fontWeight: 400 }}>
            Agents will continuously improve task output, while optimizing token cost and job latency, by sharing their memories, knowledge, and RAG tools in the network.
          </h3>
        </div>

        <div style={{ display: "flex", flexDirection: mobileMode ? "column" : undefined, alignItems: mobileMode ? "center" : undefined, height: mobileMode && demoId === null ? "300px" : mobileMode ? "800px" : undefined }}>
          {usecaseList
            .filter(item => mobileMode && demoId !== null ? usecaseList.indexOf(item) === demoId : item)
            .map((item, i) => {
              const { title, expShort, codeString, detailUrl } = item
              const index = usecaseList.indexOf(item)

              return (
                <div key={i} style={{ dispaly: "flex", flexDirection: "column", alignItems: "center", minWidth: "280px", maxWidth: "280px", marginBottom: "16px", minHeight: isSmallScreen ? undefined : demoId === 1 ? "1200px" : demoId === index ? "500px" : "200px" }}>
                  <button
                    className={demoId === i ? "button is-dark has-text-primary" : "Hover button is-primary is-outlined"}
                    aria-label="button"
                    style={{ margin: "15px 15px 5px", display: "block", height: "50px", width: "240px", maxWidth: "240px", fontSize: "18px", fontWeight: 500 }}
                    onClick={e => { setDemoId(demoId === null ? index : demoId === index ? null : index); handleGtag(e, "home") }}
                    onMouseEnter={() => setDisplayExp(index)} onMouseLeave={() => setDisplayExp(null)}
                  >
                    {title}
                  </button>

                  {displayExp === i && expShort !== undefined &&
                    <p className="GreyHover" style={{ fontSize: "12px", margin: "0 20px" }}>
                      <GoInfo />&nbsp;{expShort}
                    </p>
                  }

                  {demoId === index &&
                    <div style={{ marginTop: "30px", position: "absolute", left: isSmallScreen ? "5vw" : "15vw", width: mobileMode ? "360px" : tabletMode ? "640px" : "640px" }}>
                      <CodeSnippet codeString={codeString} styleProps={{ width: "100%" }} />
                      <p className="help">
                        <a href={detailUrl ? detailUrl : null} style={{ textDecoration: "underline" }}>{title.toLowerCase()}</a> build with versionhq
                      </p>
                      {index === 0 && <IOSectionHelloWorld />}
                      {index === 1 && <IOSectionNetwork />}
                    </div>
                  }
                </div>
              )
            })}
        </div>
      </div>

      <div className="container content" style={{ ...containerStyle }}>
        <p style={{ fontSize: "18px", fontWeight: 700 }}>AI agents on:</p>
      </div>

      <hr style={{ margin: 0 }} />

      <div style={{ display: "flex", width: "100%", justifyContent: "center", maxHeight: mobileMode ? undefined : "100px", minHeight: "100px" }}>
        <button aria-label="previous" onClick={e => {
          setModelStartId(
            modelStartId - defaultNum < 0 && defaultNum === 1
              ? modelList.length - 1
              : modelStartId - defaultNum < 0
                ? 0
                : modelStartId - defaultNum
          )
          handleGtag(e, "home", "model back")
        }}>
          <IoIosArrowBack />
        </button>

        {modelList
          .sort((a, b) => Number(b.isNew) - Number(a.isNew))
          .sort((a, b) => Number(b.isHighlight) - Number(a.isHighlight))
          // .filter(item => item.isHighlight === true)
          .filter(item => (modelList.indexOf(item) >= modelStartId && modelList.indexOf(item) < Number(modelStartId) + defaultNum))
          .map((item, i) =>
            <ModelButton
              key={i}
              id={modelList.indexOf(item)}
              model={item}
              selectedModelId={selectedModelId}
              setSelectedModelId={setSelectedModelId}
            />
          )
        }

        <button aria-label="next" onClick={e => {
          setModelStartId(modelStartId + defaultNum > modelList.length - 1 ? 0 : modelStartId + defaultNum)
          handleGtag(e, "model", "model proceed")
        }}>
          <IoIosArrowForward />
        </button>
      </div>
    </HelmetProvider >
  )
}
