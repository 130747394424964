// adding items to be used across multiple components/pages
export const demoUrl = "https://business.linkedin.com/"

export const usecaseList = [
  {
    title: "Hello World",
    codeString: "import versionhq as vhq\n\ntask = vhq.Task(description='greet {name}')\ntask.execute()",
    detailUrl: "", // connect with playground url later
  },
  {
    title: "Drafting promo plans",
    codeString: "import versionhq as vhq\n\nnetwork = vhq.form_agent_network(\n   task=f\"create a promo plan to attract the client: {client_overview}\",\n   expected_outcome='media mix, key messages, and CTA targets.'\n)\nnetwork.launch()",
    detailUrl: "", // connect with playground url later
  },
  // {
  //   title: "Stripe Payment",

  // },
  // {
  //   title: "Hubspot",

  // },
  {
    title: "Customizing agents",
    codeString: "import versionhq as vhq\n\nagent = vhq.Agent(\n   role='Demo Manager',\n   goal='run a demo successfully'\n)\n\n## updating LLM config and adding knowledge, tools, and memories.\nagent.update(\n   llm='gemini-2.0',\n   llm_config = dict(\n      temperature=1,\n      top_p=0.1,\n      stop='Response'\n   ),\n   knowledge_sources = [\n      <URL>,\n      <FILE_PATH_TO/demo.csv>,\n      <FILE_PATH_TO/demo.pdf>\n   ],\n   tools=[vhq.Tool(func=lambda x: x * 5)],\n   use_memory=True\n)"
  },
]


export const modelList = [
  {
    key: "",
    name: "Deepseek R1",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115313/pj_m_model_logos/deepseek_uogple.png",
    exp: "Deepseek's most advanced model",
    isNew: false,
    isHighlight: true,
  },
  {
    key: "",
    name: "Qwen 2.5",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115833/pj_m_model_logos/qwen_zah09d.png",
    exp: "Alibaba's most advanced model",
    isNew: false,
    isHighlight: true,
  },
  {
    key: "gemini/gemini-2.0-flash-exp",
    name: "Gemini 2.0 Flash",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115167/pj_m_model_logos/gemini_hz1evn.png",
    exp: "Google's latest and fastest model",
    isNew: false,
    isHighlight: true,
  },
  {
    key: "gemini/gemini-1.5-pro",
    name: "Gemini 1.5 Pro",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115167/pj_m_model_logos/gemini_hz1evn.png",
    exp: "Google's most advanced model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "gemini/gemini-1.5-flash",
    name: "Gemini 1.5 Flash",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115167/pj_m_model_logos/gemini_hz1evn.png",
    exp: "Google's latest and fastest model",
    isNew: false,
    isHighlight: false,
  },
  // {
  //   key: "",
  //   name: "Gemini 1.0 Pro",
  // logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115167/pj_m_model_logos/gemini_hz1evn.png",
  //   exp: "Google's legacy model",
  //   isNew: false,
  //   isHighlight: false,
  // },
  {
    key: "gpt-4o",
    name: "GPT-4o",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/gpt_new_ttekyf.png",
    exp: "OpenAI's most advanced language model",
    isNew: false,
    isHighlight: true,
  },
  {
    key: "gpt-4o-mini",
    name: "GPT-4o mini",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/gpt_new_ttekyf.png",
    exp: "OpenAI's most efficient language model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "o1-mini",
    name: "o1 Mini",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115315/pj_m_model_logos/gpt_old_z89qss.png",
    exp: "OpenAI's efficient reasoning model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "o1-preview",
    name: "o1 Preview",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115315/pj_m_model_logos/gpt_old_z89qss.png",
    exp: "OpenAI's advanced reasoning model",
    isNew: false,
    isHighlight: false,
  },
  // {
  //   key: "gpt-4",
  //   name: "GPT-4",
  //   logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/gpt_new_ttekyf.png",
  //   exp: "OpenAI's legacy language model",
  //   isNew: false
  // },
  {
    key: "",
    name: "Claude 3.5 Sonnet",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/anthropic_nhzori.png",
    exp: "Anthropic's most advanced model",
    isNew: false,
    isHighlight: true,
  },
  {
    key: "",
    name: "Claude 3 Sonnet",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/anthropic_nhzori.png",
    exp: "Anthropic's legacy efficient model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "",
    name: "Claude 3 Opus",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/anthropic_nhzori.png",
    exp: "Anthropic's legacy advanced model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "",
    name: "Claude 3 Haiku",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115316/pj_m_model_logos/anthropic_nhzori.png",
    exp: "Anthropic's fastest model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "",
    name: "Llama 3.2 90B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115314/pj_m_model_logos/llama_pzv7kp.png",
    exp: "Meta's most advanced model",
    isNew: false,
    isHighlight: true,
  },
  {
    key: "",
    name: "Llama 3.2 11B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115314/pj_m_model_logos/llama_pzv7kp.png",
    exp: "Meta's most efficient model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "",
    name: "Llama 3.1 405B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115314/pj_m_model_logos/llama_pzv7kp.png",
    exp: "Meta's legacy advanced model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "",
    name: "Llama 3.1 70B",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115314/pj_m_model_logos/llama_pzv7kp.png",
    exp: "Meta's legacy efficient model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "",
    name: "Mistral Large 2",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115314/pj_m_model_logos/mistral_utpmjs.png",
    exp: "Mistral AI's most advanced model",
    isNew: false,
    isHighlight: true,
  },
  {
    key: "",
    name: "DBRX - Instruct",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115669/pj_m_model_logos/databricks_h1cth8.png",
    exp: "Databricks' latest model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "",
    name: "Command R+",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115668/pj_m_model_logos/cohere_jmaw0l.png",
    exp: "Cohere's advanced model",
    isNew: false,
    isHighlight: false,
  },
  {
    key: "",
    name: "Command R",
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/v1739115668/pj_m_model_logos/cohere_jmaw0l.png",
    exp: "Cohere's fastest model",
    isNew: false,
    isHighlight: false,
  },
  // {
  //   name: "Solar 1 Mini",
  //   logo: "",
  //   exp: "Upstage's latest model",
  //  isNew: false,  
  // },
]

