import { useState, useEffect } from "react"
import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco, dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { apiRouteUrl } from "../lib/.api"
import { handleGtag } from "./_utils/gtag"


export const CodeSnippet = ({ codeString = null, styleProps = null }) => {
  codeString = codeString ? codeString : "import versionhq as vhq\n\ndef test(msg: str) => str: \n  return msg"
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

  return (
    <div style={{ ...styleProps }}>
      <SyntaxHighlighter language="python" style={isDarkMode ? dracula : docco} wrapLongLines={true}>
        {codeString}
      </SyntaxHighlighter>
    </div >
  )
}


export const IOSectionHelloWorld = () => {
  const [submit, setSubmit] = useState(false)
  const [input, setInput] = useState(null)
  const [output, setOutput] = useState(null)
  const [evl, setEvl] = useState(true)
  const [isExecuting, setIsExecuting] = useState(false)
  const [score, setScore] = useState(null); const [tokens, setTokens] = useState(null); const [latency, setLatency] = useState(null)

  useEffect(() => {
    const ex = async () => {
      setIsExecuting(true)

      await fetch(`${apiRouteUrl}/hello-world`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Origin": window.location.origin,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: evl === true ? JSON.stringify({ name: input, evl }) : JSON.stringify({ name: input })
      })
        .then(res => {
          if (res.status !== 200 || res.data === null) { setIsExecuting(false) }
          else return Promise.resolve(res.clone().json())
        })
        .then(res => {
          setOutput(res["message"])
          if (evl === true) { setScore(res["score"]); setLatency(res["latency"]); setTokens(res["tokens"]) }
        })
        .catch(err => { setIsExecuting(false); setOutput(`Hello world, ${input}`) })
      setIsExecuting(false)
    }

    if (output === null && submit === true && input) ex()
  }, [input, output, submit, evl])


  return (
    <div className="card" style={{ padding: "10px" }}>

      <div className="card" style={{ padding: "10px", border: "solid black 0.1px", borderRadius: "5px" }}>
        <label className="label">name</label>
        {submit
          ? <p>{input}</p>
          : <input className="input" type="text" placeholder="John Doe" onChange={e => setInput(e.target.value)} />
        }

        <label className="checkbox">
          <input
            type="checkbox"
            defaultChecked
            onClick={e => { setEvl(e.target.checked); handleGtag(e, `home demo hello world`) }}
            disabled={submit}
            style={{ accentColor: "black" }}
          />
          &nbsp;evaluate output
        </label>
      </div>


      <div className="buttons">
        <button
          className={isExecuting ? "button is-loading" : "button"}
          onClick={e => { setInput(null); setOutput(null); setSubmit(false); handleGtag(e, `home demo hello world`) }}
        >
          Clear
        </button>
        <button
          className={isExecuting ? "button is-dark is-loading" : "button is-dark"}
          style={{ width: "120px" }}
          onClick={e => { setSubmit(true); handleGtag(e, `home demo hello world`) }}>
          Submit
        </button>
      </div >

      <div className="card" style={{ padding: "10px", border: "solid black 0.1px", borderRadius: "5px" }}>
        <label className="label">Output</label>
        {output
          ?
          <div>
            <p>{output}</p>
            {evl && latency && <p className="help">Scores: {score}/1.0, job latency: {latency} sec, tokens consumed: {tokens}</p>}
          </div>
          : <input className="input" type="text" placeholder="Agent's response" />}
      </div>

    </div >
  )
}


export const IOSectionNetwork = () => {
  const [submit, setSubmit] = useState(false)
  const [input, setInput] = useState(null)
  // const [output, setOutput] = useState(null)
  const [isExecuting, setIsExecuting] = useState(false)
  const [formation, setFormation] = useState(null); const [agents, setAgents] = useState([]); const [tasks, setTasks] = useState([])

  useEffect(() => {
    const ex = async () => {
      setIsExecuting(true)

      await fetch(`${apiRouteUrl}/demo-network`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Origin": window.location.origin,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ client_overview: input })
      })
        .then(res => {
          if (res.status !== 200 || res.data === null) { setIsExecuting(false) }
          else return Promise.resolve(res.clone().json())
        })
        .then(res => {
          setFormation(res["formation"]); setAgents(res["agents"]); setTasks(res["tasks"]); setIsExecuting(false)
        })
        .catch(err => { setIsExecuting(false); })
      setIsExecuting(false)
    }

    if (formation === null && submit === true && input) ex()
  }, [input, formation, submit])


  return (
    <div className="card" style={{ padding: "10px" }}>

      <div className="card" style={{ padding: "10px", border: "solid black 0.1px", borderRadius: "5px" }}>
        <label className="label">client_overview</label>
        {submit
          ? <p>{input}</p>
          :
          <input
            className="input"
            type="text"
            placeholder="Sarah, a 30 yo founder of a SaaS startup, needs solutions to scale business."
            onChange={e => setInput(e.target.value)}
          />
        }
      </div>


      <div className="buttons">
        <button
          className={isExecuting ? "button is-loading" : "button"}
          onClick={e => { setInput(null); setTasks(null); setAgents(null); setFormation(null); setSubmit(false); handleGtag(e, `home demo network`) }}
        >
          Clear
        </button>
        <button
          className={isExecuting ? "button is-dark is-loading" : "button is-dark"}
          style={{ width: "120px" }}
          onClick={e => { setSubmit(true); handleGtag(e, `home demo network`) }}>
          Submit
        </button>
      </div >

      <div className="card" style={{ padding: "10px", border: "solid black 0.1px", borderRadius: "5px" }}>
        <label className="label">Output</label>
        {formation
          ? <p><span style={{ fontWeight: 700 }}>Formation: </span>{formation}</p>
          : <input className="input" type="text" placeholder="Launched network" />
        }
        {agents && agents.length > 0 &&
          <div style={{ marginBottom: "10px" }}>
            <p style={{ fontWeight: 700 }}>Agents: </p>
            {agents.map((item, i) => <p><span key={i} style={{ fontWeight: 700 }}>{i + 1}. </span>{item}</p>)}
          </div>

        }
        {tasks && tasks.length > 0 &&
          <p>
            <span style={{ fontWeight: 700 }}>Tasks to handle: </span>
            {tasks.map((item, i) => <p key={i}><span style={{ fontWeight: 700 }}>{i + 1}. </span>{item}</p>)}
          </p>
        }
      </div>
    </div >
  )
}