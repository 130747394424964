import { useState, useEffect } from "react"
import { createMessage } from "../../lib/.api"
import { TextArea, P, S } from "./__init__"
import { FaInfoCircle } from "react-icons/fa"


const CreateMessage = ({
  tabletMode, mobileMode, activeId, complete, setComplete, currentId, selectedValue, setSelectedValue, setActiveId
}) => {
  const [message, setMessage] = useState(null)
  const [evaluations, setEvaluations] = useState([])
  const [aggregateScore, setAggregateScore] = useState(null)
  const [isSelecting, setIsSelecting] = useState(false)
  const [error, setError] = useState(false)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    const execute = async () => {
      setIsSelecting(true)
      await createMessage()
        .then(res => {
          if (res.status !== 200 || res.data === null) { setIsSelecting(false); setError(true) }
          else return res.data.output
        })
        .then(res => {
          setMessage(res.message); setAggregateScore(res.aggregate_score); setEvaluations(res.evaluations)
          setComplete(true); setIsSelecting(false); setActiveId(activeId + 1)
        })
        .catch(err => { setIsSelecting(false); setError(true); setEditMode(true) })
    }
    if (message === null && activeId === currentId) execute()
  }, [message, activeId, setComplete, currentId, setActiveId])


  return (
    <div>
      {isSelecting && <p style={{ color: "#414141" }}>...drafting messages...</p>}
      {error && <p></p>}

      {!isSelecting && editMode &&
        <div>
          <TextArea
            editMode={editMode}
            setEditMode={setEditMode}
            mobileMode={mobileMode}
            tabletMode={tabletMode}
            title={`Option ${currentId - 2}`}
            value={message}
            setValue={setSelectedValue}
          />
          <button onClick={() => setComplete(true)}>ok</button>
        </div>
      }
      {!isSelecting && !editMode && complete && activeId <= 6 &&
        <>
          <S
            mobileMode={mobileMode}
            tabletMode={tabletMode}
            title={`Option ${currentId - 2}`}
            value={message}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            disabled={Boolean(activeId >= 7)}
          />
          <P
            mobileMode={mobileMode}
            tabletMode={tabletMode}
            title={<><FaInfoCircle />&nbsp;Performance score</>}
            value={
              <div>
                <p style={{ fontWeight: 700 }}>Overall: {aggregateScore !== null && aggregateScore !== 0 ? aggregateScore : "-"}</p>
                <br />
                <div>
                  {evaluations.map((item, i) => {
                    const { score, suggestion, criteria } = item
                    return (
                      <div key={i} style={{ marginBottom: "10px" }}>
                        <p style={{ fontWeight: 700 }}>- {criteria}:&nbsp;{score} </p>
                        <p style={{ fontSize: "12px" }}>{suggestion}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            }
          />
        </>
      }
      {activeId > 6 &&
        <>
          <P
            mobileMode={mobileMode}
            tabletMode={tabletMode}
            title={`Option ${currentId - 2}`}
            value={error ? null : message}
          />
          <P
            mobileMode={mobileMode}
            tabletMode={tabletMode}
            title={<><FaInfoCircle />&nbsp;Performance score</>}
            value={
              <>
                <p style={{ fontWeight: 700 }}>Overall: {aggregateScore !== null && aggregateScore !== 0 ? aggregateScore : "-"}</p>
                <br />
                <div>
                  {evaluations.map((item, i) => {
                    const { score, suggestion, criteria } = item
                    return (
                      <div key={i} style={{ marginBottom: "10px" }}>
                        <p style={{ fontWeight: 700 }}>- {criteria}:&nbsp;{score} </p>
                        <p style={{ fontSize: "12px" }}>{suggestion}</p>
                      </div>
                    )
                  })}
                </div>
              </>
            }
          />
        </>
      }
    </div >
  )
}


export const MessageIdea = ({
  activeId, setActiveId, tabletMode, mobileMode, currentId, selectedValue, setSelectedValue
}) => {
  const [startPrediction, setStartPrediction] = useState(false)

  return (
    <div className="container Playground-sub-container">
      <CreateMessage
        currentId={currentId}
        activeId={activeId}
        tabletMode={tabletMode}
        mobileMode={mobileMode}
        complete={startPrediction}
        setComplete={setStartPrediction}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        setActiveId={setActiveId}
      />
    </div>
  )
}
