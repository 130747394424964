import axios from "axios"
import { useState, useRef } from "react"
import { FaUpload } from "react-icons/fa"
import { Loading } from "./Message"
const imgUploadUrl = process.env.REACT_APP_IMAGE_UPLOAD_URL
const apiKey = process.env.REACT_APP_CLOUDINARY_API_KEY
const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET


export const UploadWidget = ({ message = "Upload", file, setFile, setImage = null, width = "170px" }) => {
  const [isUploaded, setIsUploaded] = useState(false)
  const [error, setError] = useState(false)
  let isUploading = useRef(false)

  // const timestamp = Math.floor(Date.now() / 1000)
  // const eager = "f_webp,h_300,w_300"
  // const baseString = `eager=${eager}&eager_async=${true}&timestamp=${timestamp}&upload_preset=${uploadPreset}${apiSecret}`
  // const signature = crypto.createHash("sha1").update(baseString).digest("hex")

  const handleUpload = e => {
    e.preventDefault()
    isUploading.current = true

    let formData = new FormData()
    formData.append("file", e.target.files[0])
    formData.append("api_key", apiKey)
    formData.append("upload_preset", uploadPreset)

    for (var key of formData.entries()) {
      if (window.location.hostname === "localhost") console.log(key[0] + ", " + key[1])
    }

    axios.post(imgUploadUrl, formData)
      .then(res => {
        isUploading.current = false
        const url = res.data.secure_url ? res.data.secure_url : res.data.url
        if (url !== undefined && res.status === 200) { setFile(url); setIsUploaded(true); if (setImage) setImage(url); }
        else setError(true)
      })
      .catch(err => { isUploading.current = false; setError(true) })
  }

  if (error) return (<p>Something went wrong.</p>)
  return (
    <div className="field-body" style={{ maxWidth: width }}>
      <div className="field">
        <div className="control">
          <div className="control">

            {isUploading.current
              ? <Loading />
              : isUploaded && file
                ?
                <div>
                  <a href={file} target="_blank" rel="noreferrer" style={{ textDecoration: "underline", }}>File</a>
                  {/* <img
                    src={file}
                    alt="Uploaded file"
                    width={192}
                    height={192}
                    sizes="(max-width: 420px) 96px, (max-width: 1300px) 150px, 192px"
                    style={{ borderRadius: "50%", overflow: "hidden", }}
                  /> */}
                </div>
                :
                <div className="file has-name is-boxed">
                  <label className="file-label">
                    <input className="file-input" type="file" onChange={(e) => handleUpload(e)} />
                    <span className="file-cta">
                      <span className="file-icon" style={{ marginInlineEnd: 0 }}><FaUpload /></span>
                      <span className="file-label" style={{ fontSize: "12px" }}>{message}</span>
                    </span>
                    <span className="file-name" style={{ fontSize: "10px", minWidth: "100%" }}></span>
                  </label>
                </div>
            }
          </div>

          {error && <p className="exp-error">Something went wrong.</p>}
        </div>
      </div>
    </div>
  )
}


// //https://cloudinary.com/documentation/image_transformations#transformation_url_structure
// //https://cloudinary.com/documentation/image_upload_api_reference
// //https://cloudinary.com/documentation/upload_images#generating_authentication_signatures