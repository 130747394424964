import { useEffect, useState, useRef } from "react"
import { UploadWidget } from "./_utils/UploadWidget"
import { apiRouteUrl } from "../lib/.api"


export const KnowledgeFeed = () => {
  const [start, setStart] = useState(false)
  const [feeding, setFeeding] = useState(false)
  const [error, setError] = useState(false)
  let textInput = useRef(null)
  const [file, setFile] = useState(null)
  const [leader, setLeader] = useState(null)

  useEffect(() => {
    const rep = async () => {
      setFeeding(true)
      await fetch(`${apiRouteUrl}/feed-knowledge`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Origin": window.location.origin,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ file_url: file, text: textInput.current })
      })
        .then(res => {
          if (res.status !== 200 || res.data === null) { setFeeding(false); setError(true) }
          else return Promise.resolve(res.clone().json())
        })
        .then(res => { setLeader(res["output"]); setFeeding(false) })
        .catch(err => { setFeeding(false); setError(true); })
    }
    if (start) rep()
  }, [start, file])


  return (
    <div className="container content">
      <p>Knowledge source</p>

      {leader
        ?
        <div>
          <p>Your leader agent has learned the knowledge.</p>
          <p>{leader !== null && "id" in leader && `ID: ${leader["id"]}`}</p>
          <input className="input" type="text" placeholder="Query the knowledge. Ask related question." disabled />
        </div>
        :
        feeding
          ?
          <p>...feeding... (We are updating a storage - takes about 10 seconds.)</p>
          :
          <div>
            <UploadWidget
              message="json, xsl, doc, txt, ppt, pdf, md, asciidoc"
              width="170px"
              file={file}
              setFile={setFile}
            />
            <div style={{ minWidth: "500px", marginTop: "20px" }}>
              <textarea
                className="textarea"
                placeholder="https://url-to-refer-1.com&#10;https://url-to-refer-2.com/amazing-reference&#10;Quantum entanglement is a concept in quantum mechanics. At the quantum level, particles have properties like spin that are quantized. This means that when you measure the spin of a particle, you can only get certain discrete values, not just any value. Now, imagine you have two particles that are entangled. This means that their properties are linked in a very special way, no matter how far apart they are. For example, if you measure the spin of one particle and find it to be up, you instantly know that the spin of the other particle must be down, even if they are light-years away. The truly bizarre thing is that this correlation seems to happen instantaneously. It's as if the particles are communicating with each other faster than the speed of light, which Einstein's theory of relativity says is impossible. This is what he called spooky action at a distance.&#10;https://another-amazing-ref.com/"
                onChange={e => textInput.current = e.target.value}
                rows="16"
              />
              <p className="help">Provide URLs or text, each on a new line. max. 2,000 characters</p>
            </div>
            {error && <p className="help is-danger">Something went wrong.</p>}
          </div>
      }
      {leader === null &&
        <button className={feeding ? "button is-dark is-loading" : "button is-dark"} onClick={() => setStart(true)} style={{ margin: "30px 0" }}>
          Feed
        </button>
      }
    </div>
  )
}