import { useState, useEffect } from "react"
import { buildingHypo } from "../../lib/.api"
import { handleGtag } from "../_utils/gtag"
import { TextArea, P } from "./__init__"


export const BuildingHypo = ({ tabletMode, mobileMode, activeId, setActiveId }) => {
  const [hypo, setHypo] = useState(null)
  const [isSelecting, setIsSelecting] = useState(false)
  const [error, setError] = useState(false)
  const [editMode, setEditMode] = useState(true)
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    const execute = async () => {
      setIsSelecting(true)
      await buildingHypo()
        .then(res => {
          if (res.status !== 200 || res.data === null) { setIsSelecting(false); setError(true) }
          else return res.data.output
        })
        .then(res => { setHypo(res.hypo); setIsSelecting(false) })
        .catch(err => { setIsSelecting(false); setError(true); setEditMode(true) })
    }

    if (hypo === null && activeId === 2) execute();
  }, [hypo, activeId])

  return (
    <div className="container Playground-sub-container">
      {isSelecting && <p style={{ color: "#414141" }}>...building hypothesis to test...</p>}
      {error && <p className="GreyHover" style={{ fontSize: "12px" }}>Something went wrong. Input manually.</p>}
      {isSelecting === false &&
        <div className="container Playground-sub-container">
          {complete
            ?
            <P
              mobileMode={mobileMode}
              tabletMode={tabletMode}
              title="Hypothesis"
              value={<p>{hypo}</p>}
            />
            : <TextArea
              editMode={editMode}
              setEditMode={setEditMode}
              mobileMode={mobileMode}
              tabletMode={tabletMode}
              title={`Hypothesis`}
              value={hypo}
              setValue={setHypo}
            />
          }

          <p style={{ fontStyle: "italic" }}>Let's edit, or we'll draft messages.</p>
          {!complete &&
            <button
              style={{ textDecoration: "underline" }}
              onClick={e => { setComplete(true); setActiveId(activeId + 1); handleGtag(e, "playground", activeId) }}>
              ok
            </button>
          }
        </div>
      }
    </div>
  )
}
