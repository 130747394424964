import axios from "axios"
import { getToken } from "./.auth"
export const apiRouteUrl = window.location.hostname === "localhost" ? "http://localhost:5002/api" : "https://api.versi0n.io/api"
export const withAuthHeaders = () => { return { headers: { Authorization: `Token ${getToken()}` } } }
export const withCorsHeaders = () => {
  return {
    headers: {
      "Content-Type": "application/json",
      // "Origin": window.location.origin,
      // "Access-Control-Request-Method": "GET, POST, OPTIONS",
      // "Access-Control-Request-Headers": "*",
    }
  }
}
export const source = axios.CancelToken.source()


// core
export const fetchProduct = formData => { return axios.post(`${apiRouteUrl}/fetch-product`, formData, withCorsHeaders()) }
export const suggestAudience = () => { return axios.get(`${apiRouteUrl}/suggest-audience`, withCorsHeaders()) }
export const buildingHypo = () => { return axios.get(`${apiRouteUrl}/build-hypo`, withCorsHeaders()) }
export const createMessage = () => { return axios.get(`${apiRouteUrl}/create-message`, withCorsHeaders()) }
export const predictPerformance = () => { return axios.get(`${apiRouteUrl}/predict-performance`, withCorsHeaders()) }
export const buildWorkflow = formData => { return axios.post(`${apiRouteUrl}/build-workflow`, formData, withCorsHeaders()) }

// knowledge, storage handling
export const feedKnowledge = formData => { return axios.post(`${apiRouteUrl}/feed-knowledge`, formData, withCorsHeaders()) }

// hubspot auth
export const destinationAuth = () => { return axios.get(`${apiRouteUrl}/destination-auth`, withCorsHeaders()) }
export const deployWorkflow = formData => { return axios.post(`${apiRouteUrl}/deploy-workflow`, formData, withCorsHeaders()) }


// auth & user
export const registerUser = formData => { return axios.post(`/api/sign-up/`, formData) }
export const loginUser = formData => { return axios.post(`${apiRouteUrl}/login/`, formData) }
export const verifyEmail = (userId, formData = {}) => {
  return axios.put(`${apiRouteUrl}/verify_email/${Number(userId)}/`, formData, withAuthHeaders())
}
export const changePassword = (userId, formData) => {
  return axios.put(`${apiRouteUrl}/change_password/${Number(userId)}/`, formData, withAuthHeaders())
}
export const fetchLinkedInProfile = formData => { return axios.post(`${apiRouteUrl}/fetch_linkedin_profile/`, formData) }
export const editProfile = (userId, data) => { return axios.put(`${apiRouteUrl}/get_or_edit/${Nunber(userId)}/`, data, withAuthHeaders()) }


// company & prod
export const handleCompany = (companyId, formData = {}) => {
  return axios.get(`${apiRouteUrl}/handle-company/${companyId}/`, withAuthHeaders())
}
export const handleProduct = (companyId, productId, formData = {}) => {
  return Object.keys(formData).length > 0
    ? axios.put(`${apiRouteUrl}/handle-product/${companyId}/${productId}/`, formData, withAuthHeaders())
    : axios.get(`${apiRouteUrl}/handle-product/${companyId}/${productId}/`, withAuthHeaders())
}


// send email
export const sendEmail = formData => { return axios.post(`${apiRouteUrl}/core/send-email/`, formData) }


// // fetch third party url
// export const fetchUrl = url => { return axios.post(`${apiRouteUrl}/core/fetch-landing-page-via-url/`, { url: url }) }



export const generateTextVariations = formData => { return axios.post(`${apiRouteUrl}/gen-texts/`, formData) }
// export const predictPerformance = formData => { return axios.post(`${apiRouteUrl}/predict-performance/`, formData) }
export const genareteImage = formData => { return axios.post(`${apiRouteUrl}/gen-image/`, formData) }
export const transformImage = formData => { return axios.post(`${apiRouteUrl}/transform-image/`, formData) }


// content
export const createContent = (companyUuid, formData) => { return axios.post(`${apiRouteUrl}/create-content/${companyUuid}/`, formData, withAuthHeaders()) }
export const getContent = companyUuid => { return axios.post(`${apiRouteUrl}/get-content/${companyUuid}/`, withAuthHeaders()) }



// facebook (auth, ad, insights)
// https://developers.facebook.com/docs/marketing-apis/overview/authentication/
// https://developers.facebook.com/docs/marketing-api/guides/lead-ads/create
const facebookapiRouteUrl = "https://graph.facebook.com/v20.0"
const headers = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" }
export const createFacebookAd = (adAccountId, type, formData = null) => {
  return axios.post(`${facebookapiRouteUrl}/act_${adAccountId}/${type}`, formData, { headers })
} // type = campaigns, adsets, ads
export const getFacebookInsights = (adAccountId, accessToken) => {
  return axios.get(`${facebookapiRouteUrl}/act_${adAccountId}/insights?access_token=${accessToken}`, { headers })
}
export const retrieveFacebookAccessToken = (companyId, clientId, clientSecret, fbAuthCode) => {
  const redirectUrl = `https://versi0n.io/companies/${companyId}/fb-auth/`
  return axios.post(`https://www.facebook.com/v20.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUrl}&scope=ads_management&scope=pages_show_list&scope=ads_read&scope=business_management&scope=pages_read_engagement&scope=pages_manage_ads`, { headers })
}